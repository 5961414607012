import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby" 
import "./Hero.css"
import computer from "../../assets/vector/vector-computer.svg"
import computerPad from "../../assets/vector/vector-computer-pad.svg"
import computerGrid from "../../assets/vector/vector-computer-grid-1.svg"

const Hero = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulHome {
                edges {
                    node {
                        heroTitle
                        heroText {
                            heroText
                        }
                        heroButton {
                            text
                            url
                        }
                    }
                }
            }
        }
    `)

    const {
        heroTitle,
        heroText,
        heroButton,
    } = data.allContentfulHome.edges[0].node

    return (
        <div className="hero">
            <div className="hero-inner">
                <div className="hero-main">
                    <h1 className="hero-title">{heroTitle}</h1>
                    <p className="hero-text">{heroText.heroText}</p>
                    <Link to={heroButton.url} className="hero-button">
                        {heroButton.text}
                    </Link>
                </div>
                <div className="hero-vectors">
                    <img
                        className="hero-grid"
                        src={computerGrid}
                        alt="computer grid"
                    />
                    <img
                        className="hero-pad"
                        src={computerPad}
                        alt="computer pad"
                    />
                    <img
                        className="hero-computer"
                        src={computer}
                        alt="computer"
                    />
                </div>
            </div>
        </div>
    )
}

export default Hero
