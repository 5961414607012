import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./Goals.css"
import SectionHeader from "../SectionHeader/SectionHeader"

const Goals = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulGoalItem(sort: { fields: sortOrder, order: ASC }) {
                edges {
                    node {
                        id
                        sortOrder
                        image {
                            file {
                                url
                            }
                        }
                        title
                        text {
                            text
                        }
                        reverse
                    }
                }
            }
            allContentfulHome {
                edges {
                    node {
                        goalsSubsectionTitle
                    }
                }
            }
        }
    `)

    const { goalsSubsectionTitle } = data.allContentfulHome.edges[0].node

    return (
        <div className="Goals">
            <SectionHeader subsectionTitle={goalsSubsectionTitle} />
            <div className="Goals-cols">
                {data &&
                    data.allContentfulGoalItem.edges.map(node => (
                        <div
                            className={`Goals-col ${
                                node.node.reverse ? "Goals-col-reverse" : ""
                            }`}
                            key={node.node.id}
                        >
                            <img
                                className="Goals-image"
                                src={node.node.image.file.url}
                                alt=""
                                role="presentation"
                            />
                            <div className="Goal-text-wrap">
                                <h3 className="Goals-title">
                                    {node.node.title}
                                </h3>
                                <p className="Goals-text">
                                    {node.node.text.text}
                                </p>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default Goals
