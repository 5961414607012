import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./TechStack.css"
import SectionHeader from "../SectionHeader/SectionHeader"

const TechStack = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulHome {
                edges {
                    node {
                        techStackSubsectionTitle
                        techStackSubsectionSubtitle
                    }
                }
            }
            allContentfulTechItem(sort: { fields: sortOrder, order: ASC }) {
                edges {
                    node {
                        id
                        sortOrder
                        image {
                            description
                            fluid {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                                srcSetWebp
                                srcWebp
                                tracedSVG
                            }
                        }
                        text {
                            text
                        }
                        link {
                            text
                            url
                        }
                    }
                }
            }
        }
    `)

    const {
        techStackSubsectionTitle,
        techStackSubsectionSubtitle,
    } = data.allContentfulHome.edges[0].node

    return (
        <div className="TechStack" id="techstack">
            <SectionHeader subsectionTitle={techStackSubsectionTitle} />
            <p className="Subsection-subtitle">{techStackSubsectionSubtitle}</p>
            <div className="TechStack-cols">
                {data &&
                    data.allContentfulTechItem.edges.map(node => (
                        <div className="TechStack-col" key={node.node.id}>
                            <a
                                href={node.node.link.url}
                                className="TechStack-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className="TechStack-image">
                                    <Img fluid={node.node.image.fluid} />
                                </div>
                                <p className="TechStack-text">
                                    {node.node.text.text}
                                </p>
                            </a>
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default TechStack
