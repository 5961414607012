import React from "react"
import { Map, GoogleApiWrapper, Marker } from "google-maps-react"
import "./GoogleMap.css"

const GoogleMap = props => {
    const mapStyles = {
        width: "100%",
        height: "250px",
    }

    return (
        <Map
            google={props.google}
            zoom={15}
            style={mapStyles}
            initialCenter={{ lat: 44.84247501462495, lng: 20.402917523267863 }}
        >
            <Marker
                position={{ lat: 44.84247501462495, lng: 20.402917523267863 }}
            />
        </Map>
    )
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyAWZS6aVkh8auNKLvYRbXP8bqvXrPYZbUQ",
})(GoogleMap)
