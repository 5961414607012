import React from "react"
import PropTypes from "prop-types"

const Tab = props => {
    const onClick = () => {
        const { label, onClick } = props
        onClick(label)
    }

    const { activeTab, label } = props

    let className = "tab-list-item"
    if (activeTab === label) className += " tab-list-active"

    return (
        <button
            type="button"
            onClick={onClick}
            onKeyDown={onClick}
            className={className}
        >
            {label}
        </button>
    )
}

Tab.propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}

export default Tab
