import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import "./Career.css"
import SectionHeader from "../SectionHeader/SectionHeader"

const Career = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulHome {
                edges {
                    node {
                        careerSectionTitle
                        careerSubsectionTitle
                        careerSubsectionSubtitle
                        careerImage {
                            fluid {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                                srcSetWebp
                                srcWebp
                                tracedSVG
                            }
                            description
                        }
                    }
                }
            }
            allContentfulCareerItem(sort: { fields: sortOrder, order: ASC }) {
                edges {
                    node {
                        id
                        sortOrder
                        position
                        text {
                            text
                        }
                        link {
                            text
                            url
                        }
                    }
                }
            }
        }
    `)

    const {
        careerSectionTitle,
        careerSubsectionTitle,
        careerSubsectionSubtitle,
        careerImage,
    } = data.allContentfulHome.edges[0].node

    return (
        <div className="Career" id="career">
            <SectionHeader
                title={careerSectionTitle}
                subsectionTitle={careerSubsectionTitle}
            />
            <p className="Subsection-subtitle">{careerSubsectionSubtitle}</p>
            <div className="Career-cols">
                <div className="Career-col-narrow">
                    <Img fluid={careerImage.fluid} className="Career-image" />
                    <span className="Career-image-caption">
                        {careerImage && careerImage.description}
                    </span>
                </div>
                <div className="Career-col-wide">
                    {data &&
                        data.allContentfulCareerItem.edges.map(node => (
                            <div className={"Career-item"} key={node.node.id}>
                                <h4 className="Career-position">
                                    {node.node.position}
                                </h4>
                                <p className="Career-text">
                                    {node.node.text.text}
                                </p>
                                <Link
                                    to={`/${node.node.link.url}`}
                                    className="Career-link"
                                >
                                    {node.node.link.text}
                                </Link>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default Career
