import React from "react"
import "./ContactLocation.css"
import SectionHeader from "../SectionHeader/SectionHeader"
import mapIcon from "../../assets/icon/icon-map.svg"
import phoneIcon from "../../assets/icon/icon-phone.svg"
import emailIcon from "../../assets/icon/icon-email.svg"
import clockIcon from "../../assets/icon/icon-clock.svg"
import teOffice from "../../assets/background/background-te-office-entry-smaller.jpg"
import GoogleMap from "../GoogleMap/GoogleMap"
import Tabs from "../Tabs/Tabs"

const ContactLocation = () => {
    return (
        <React.Fragment>
            <SectionHeader title={"Contact"} subsectionTitle={"Find Us Easy"} />
            <div className="contact-location" id="contact">
                <div className="contact-location-content">
                    <a
                        href="https://maps.app.goo.gl/2mKjrfR4RJRcrRLE8"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="contact-location-item"
                    >
                        <img
                            className="contact-location-icon"
                            src={mapIcon}
                            alt="map icon"
                        />
                        <div className="contact-location-text">
                            <p className="contact-location-line">
                                Novogradska 67v/16
                            </p>
                            <p className="contact-location-line">
                                11080 Belgrade, Serbia
                            </p>
                        </div>
                    </a>
                    <a
                        href={`tel:+381116305914`}
                        className="contact-location-item"
                    >
                        <img
                            className="contact-location-icon"
                            src={phoneIcon}
                            alt="phone icon"
                        />
                        <p className="contact-location-line">
                            Call Us +381 11 630 5914
                        </p>
                    </a>
                    <a
                        href={`mailto:info@thinkingengineers.com`}
                        className="contact-location-item"
                    >
                        <img
                            className="contact-location-icon"
                            src={emailIcon}
                            alt="email icon"
                        />
                        <p className="contact-location-line">
                            info@thinkingengineers.com
                        </p>
                    </a>
                    <div className="contact-location-item">
                        <img
                            className="contact-location-icon"
                            src={clockIcon}
                            alt="clock icon"
                        />
                        <p className="contact-location-line">
                            Monday - Friday, 9AM - 5PM
                        </p>
                    </div>
                </div>
                <Tabs>
                    <div label="Map">
                        <GoogleMap />
                    </div>
                    <div label="Office">
                        <img
                            src={teOffice}
                            alt="Thinking engineers office"
                            className="tab-image"
                        />
                    </div>
                </Tabs>
            </div>
        </React.Fragment>
    )
}

export default ContactLocation
