import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import ScrollUpButton from "react-scroll-up-button"
import Layout from "../components/layout"
import { greyDarker } from "../constants/cssVariables"
import { slopeLeft, slopeDark } from "../constants/classVariables"
import Section from "../components/Section/Section"
import Hero from "../components/Hero/Hero"
import Intro from "../components/Intro/Intro"
import TechStack from "../components/TechStack/TechStack"
import Goals from "../components/Goals/Goals"
import goalsImage from "../../src/assets/background/background-goals.png"
import Workflow from "../components/Workflow/Workflow"
import CaseStudy from "../components/CaseStudy/CaseStudy"
import Testimonials from "../components/Testimonials/Testimonials"
import Career from "../components/Career/Career"
import ContactLocation from "../components/ContactLocation/ContactLocation"
import ContactForm from "../components/ContactForm/ContactForm"

const IndexPage = props => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulHome {
                edges {
                    node {
                        homeSeoTitle
                        homeSeoDescription
                    }
                }
            }
        }
    `)

    const {
        homeSeoTitle,
        homeSeoDescription,
    } = data.allContentfulHome.edges[0].node

    return (
        <Layout location={props.location}>
            <SEO title={homeSeoTitle} description={homeSeoDescription} />
            <Hero />
            <Section>
                <Intro />
            </Section>
            <Section
                bgColor={greyDarker}
                slopeSide={`${slopeLeft} ${slopeDark}`}
            >
                <TechStack />
            </Section>
            <Section
                bgImage={goalsImage}
                slopeSide={slopeLeft}
                cssClass="Section-has-bg"
            >
                <Goals />
            </Section>
            <Section>
                <Workflow />
            </Section>
            <Section bgColor={greyDarker} slopeSide={slopeLeft}>
                <CaseStudy />
            </Section>
            <Section>
                <Testimonials />
            </Section>
            <Section bgColor={greyDarker} slopeSide={`${slopeLeft} ${slopeDark}`}>
                <Career />
            </Section>
            <Section slopeSide={slopeLeft}>
                <ContactLocation />
            </Section>
            <Section bgColor={greyDarker} >
                <ContactForm locationState={props.location.state} />
            </Section>
            <ScrollUpButton ContainerClassName="back-to-top" />
        </Layout>
    )
}

export default IndexPage
