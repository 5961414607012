import React, { useState } from "react"
import "./ContactForm.css"
import SectionHeader from "../SectionHeader/SectionHeader"
import { submitForm } from "../../constants/URLconstants"
import Reaptcha from "reaptcha"

const ContactForm = props => {
    const initialState = {
        name: "",
        email: "",
        phone: "",
        message: "",
        linkedinUrl: "",
        sendCopy: false,
        position: props.locationState ? props.locationState.position : "",
    }

    const [isSent, setIsSent] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [formInput, setFormInput] = useState(initialState)
    const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)

    const postFormData = () => {
        return fetch(submitForm, {
            method: "POST",
            mode: "no-cors",
            header: {
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(formInput),
        })
    }

    const handleSubmit = function(e) {
        e.preventDefault()

        if (!isRecaptchaVerified) {
            setHasError(true)
            return
        }

        postFormData()
            .then(res => res.json())
            .catch(error => console.log(error))
            .then(() => {
                setIsSent(true)
                setFormInput(initialState)
            })
    }

    const renderThankYouMessage = () => {
        if (isSent) {
            return (
                <div className="contact-form-message">
                    <h3 className="contact-form-title">Thank You!</h3>
                    <p className="contact-form-text">
                        Your message was successfully sent. We’ll get back to
                        you as soon as possible.
                    </p>
                </div>
            )
        }

        return null
    }

    const handleForm = e => {
        const value =
            e.target.type === "checkbox" ? e.target.checked : e.target.value
        setFormInput({ ...formInput, [e.target.name]: value })
    }

    const focusInvalidInput = () => {
        var elements = document.querySelectorAll("input,select,textarea")
        var invalidListener = function() {
            this.scrollIntoView(false)
        }

        for (var i = elements.length; i--; )
            elements[i].addEventListener("invalid", invalidListener)
    }

    const verifyRecaptcha = recaptchaResponse => {
        setHasError(false)
        setIsRecaptchaVerified(true)
    }

    return (
        <div className="contact-form" id="contactForm">
            <SectionHeader subsectionTitle={"Contact Us"} />
            <p className="Subsection-subtitle">
                Want to hire us or to work with us? Drop us a line!
            </p>
            <form className="contact-form-form" onSubmit={handleSubmit}>
                <input
                    id="input-name"
                    className="contact-form-input input-name"
                    type="text"
                    placeholder="Name*"
                    name="name"
                    required
                    value={formInput.name}
                    onChange={e => handleForm(e)}
                    onInvalid={e => focusInvalidInput(e)}
                />
                <input
                    id="input-email"
                    className="contact-form-input input-email"
                    type="email"
                    placeholder="Email*"
                    name="email"
                    required
                    value={formInput.email}
                    onChange={e => handleForm(e)}
                    onInvalid={e => focusInvalidInput(e)}
                />
                <input
                    id="input-phone"
                    className="contact-form-input input-phone"
                    type="tel"
                    placeholder="Phone"
                    name="phone"
                    step="1"
                    value={formInput.phone}
                    onChange={e => handleForm(e)}
                />
                <input
                    id="input-linkedin"
                    className="contact-form-input"
                    type="text"
                    placeholder="Linkedin"
                    name="linkedinUrl"
                    value={formInput.linkedinUrl}
                    onChange={e => handleForm(e)}
                />
                <textarea
                    id="input-message"
                    className="contact-form-textarea"
                    rows="6"
                    placeholder="Message*"
                    name="message"
                    maxLength="360"
                    required
                    value={formInput.message}
                    onChange={e => handleForm(e)}
                    onInvalid={e => focusInvalidInput(e)}
                ></textarea>
                <div className="contact-form-radio">
                    <label htmlFor="checkbox">
                        Send me a copy of this message
                    </label>
                    <input
                        type="checkbox"
                        id="checkbox"
                        name="sendCopy"
                        value={formInput.sendCopy}
                        onChange={e => handleForm(e)}
                    />
                    <div className="box"></div>
                </div>
                <Reaptcha
                    sitekey="6Lc4WMoUAAAAALVRjao5irbHUpzwrZu5p6YvurdX"
                    onVerify={verifyRecaptcha}
                />

                {hasError ? (
                    <span className="contact-form-error">
                        Please verify reCAPTCHA before submitting.
                    </span>
                ) : null}

                <button className="contact-form-button" type="submit">
                    Send Message
                </button>
            </form>
            <div className="contact-form-visual">{renderThankYouMessage()}</div>
        </div>
    )
}

export default ContactForm
