import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Link } from "gatsby"
import "./CaseStudy.css"
import SectionHeader from "../SectionHeader/SectionHeader"
import stMobile from "../../assets/background/background-st-mobile.jpg"
import stDesktop from "../../assets/background/background-st-desktop.jpg"
import arrowCurved from "../../assets/icon/icon-arrow-curved.svg"

const CaseStudy = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulHome {
                edges {
                    node {
                        caseStudySectionTitle
                        caseStudySubsectionTitle
                        caseStudyTitle
                        caseStudyTextTop {
                            json
                        }
                        caseStudyTextBottom {
                            json
                        }
                    }
                }
            }
        }
    `)

    const {
        caseStudySectionTitle,
        caseStudySubsectionTitle,
        caseStudyTitle,
        caseStudyTextTop,
        caseStudyTextBottom,
    } = data.allContentfulHome.edges[0].node

    return (
        <div className="casestudy" id="case-study">
            <SectionHeader
                title={caseStudySectionTitle}
                subsectionTitle={caseStudySubsectionTitle}
            />
            <div className="casestudy-content">
                <h3 className="casestudy-title">{caseStudyTitle}</h3>
                <div className="casestudy-text casestudy-text-top">
                    {documentToReactComponents(caseStudyTextTop.json)}
                </div>
                <img
                    className="casestudy-image-mobile"
                    src={stMobile}
                    alt="social thinking mobile screenshot"
                />
                <img
                    className="casestudy-image-desktop"
                    src={stDesktop}
                    alt="social thinking desktop screenshot"
                />
                <div className="casestudy-caption">
                    <img
                        className="casestudy-arrow"
                        src={arrowCurved}
                        alt="arrow icon"
                    />
                    <a
                        className="casestudy-link"
                        href="https://socialthinking.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Visit Website
                    </a>
                </div>
                <div className="casestudy-text casestudy-text-bottom">
                    {documentToReactComponents(caseStudyTextBottom.json)}
                </div>
                <Link className="casestudy-button" to="/case-study">
                    Read More
                </Link>
            </div>
        </div>
    )
}

export default CaseStudy
