import React from "react"
import PropTypes from "prop-types"
import Tab from "./Tab"
import "./Tabs.css"

const Tabs = props => {
    const [activeTab, setActiveTab] = React.useState(
        props.children[0].props.label
    )
    const onClickTabItem = tab => setActiveTab(tab)
    const { children } = props

    return (
        <div className="tabs">
            <div className="tab-list">
                {children.map(child => {
                    const { label } = child.props
                    return (
                        <Tab
                            key={label}
                            activeTab={activeTab}
                            label={label}
                            onClick={onClickTabItem}
                        />
                    )
                })}
            </div>
            <div className="tab-content">
                {children.map(child => {
                    if (child.props.label !== activeTab) return undefined
                    return child.props.children
                })}
            </div>
        </div>
    )
}

Tabs.propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
}

export default Tabs
