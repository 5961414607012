import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"
import "./Intro.css"
import SectionHeader from "../SectionHeader/SectionHeader"

const Intro = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulHome {
                edges {
                    node {
                        introSectionTitle
                        introSubsectionTitle
                        introImage {
                            fluid {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                                srcSetWebp
                                srcWebp
                                tracedSVG
                            }
                            description
                        }
                        introText {
                            json
                        }
                    }
                }
            }
        }
    `)

    const {
        introSectionTitle,
        introSubsectionTitle,
        introImage,
        introText,
    } = data.allContentfulHome.edges[0].node

    return (
        <div className="Intro" id="intro">
            <SectionHeader
                title={introSectionTitle}
                subsectionTitle={introSubsectionTitle}
            />
            <div className="Intro-cols">
                <div className="Intro-col-wide">
                    {introImage && <Img fluid={introImage.fluid} />}
                    <span className="Intro-image-caption">
                        {introImage && introImage.description}
                    </span>
                </div>
                <div className="Intro-col-narrow">
                    <div className="Intro-text">
                        {documentToReactComponents(introText.json)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro
