import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./Workflow.css"
import SectionHeader from "../SectionHeader/SectionHeader"
import arrowDownDesktop from "../../assets/icon/icon-arrow-thin.svg"

const Workflow = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulHome {
                edges {
                    node {
                        workflowSubsectionTitle
                        workflowSubsectionSubtitle
                    }
                }
            }
            allContentfulWorkflowItem(sort: { fields: sortOrder, order: ASC }) {
                edges {
                    node {
                        id
                        sortOrder
                        image {
                            file {
                                url
                            }
                        }
                        text {
                            text
                        }
                        title
                    }
                }
            }
        }
    `)

    const {
        workflowSubsectionTitle,
        workflowSubsectionSubtitle,
    } = data.allContentfulHome.edges[0].node

    return (
        <div className="workflow">
            <SectionHeader subsectionTitle={workflowSubsectionTitle} />
            <p className="Subsection-subtitle">{workflowSubsectionSubtitle}</p>
            <div className="workflow-content">
                {data &&
                    data.allContentfulWorkflowItem.edges.map(node => {
                        return (
                            <React.Fragment key={node.node.id}>
                                <div className="workflow-step">
                                    <img
                                        className="workflow-icon"
                                        src={node.node.image.file.url}
                                        alt="workflow icon"
                                    />
                                    <h3 className="workflow-title">
                                        {node.node.title}
                                    </h3>
                                    <p className="workflow-text">
                                        {node.node.text.text}
                                    </p>
                                </div>
                                <img
                                    className="workflow-arrow-desktop"
                                    src={arrowDownDesktop}
                                    alt="arrow icon"
                                />
                            </React.Fragment>
                        )
                    })}
            </div>
        </div>
    )
}

export default Workflow
