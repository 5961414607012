import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./Testimonials.css"
import SectionHeader from "../SectionHeader/SectionHeader"

const Testimonials = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulHome {
                edges {
                    node {
                        testimonialSubsectionTitle
                    }
                }
            }
            allContentfulTestimonialItem(
                sort: { fields: sortOrder, order: ASC }
            ) {
                edges {
                    node {
                        id
                        sortOrder
                        personImage {
                            description
                            fluid {
                                src
                                aspectRatio
                                base64
                                sizes
                                srcSet
                                srcSetWebp
                                srcWebp
                                tracedSVG
                            }
                        }
                        personNameAndSurname
                        personPosition
                        personText {
                            personText
                        }
                        personSignature {
                            fluid {
                                src
                                aspectRatio
                                base64
                                sizes
                                srcSet
                                srcSetWebp
                                srcWebp
                                tracedSVG
                            }
                        }
                    }
                }
            }
        }
    `)

    const { testimonialSubsectionTitle } = data.allContentfulHome.edges[0].node

    return (
        <div className="Testimonials">
            <SectionHeader subsectionTitle={testimonialSubsectionTitle} />
            <div className="Testimonials-cols">
                {data &&
                    data.allContentfulTestimonialItem.edges.map(node => (
                        <div className="Testimonials-col" key={node.node.id}>
                            <div className="Testimonials-image">
                                <Img fluid={node.node.personImage.fluid} />
                            </div>
                            <div className="Testimonials-text-wrap">
                                <span className="Testimonials-name">
                                    {node.node.personNameAndSurname}
                                </span>
                                <span className="Testimonials-position">
                                    {node.node.personPosition}
                                </span>
                                <p className="Testimonials-text">
                                    {node.node.personText.personText}
                                </p>
                                <div className="Testimonials-signature">
                                    <Img
                                        fluid={node.node.personSignature.fluid}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default Testimonials
