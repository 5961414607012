import React from "react"
import "./SectionHeader.css"
import branch from "../../../src/assets/vector/vector-branch.svg"

const SectionHeader = ({ title, subsectionTitle }) => {
    return (
        <div className="SectionHeader">
            {title && <span className="Section-title">{title}</span>}
            <h2 className="Subsection-title">
                {subsectionTitle}
                <img
                    className="SectionHeader-image"
                    src={branch}
                    alt=""
                    role="presentation"
                />
            </h2>
        </div>
    )
}

export default SectionHeader
